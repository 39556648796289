import React from 'react';

import { st, classes } from './Widget.st.css';

import { EmptyList } from '../components/EmptyList';

import { setWidthRestrictions } from '../../../services/resizeWindow';
import {
  IWixSDKContext,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { IChallengeListStateProps } from './interfaces';
import { Spinner } from '../../../components-shared/Spinner';
import { Title } from '../components/Title';
import {
  Tabs,
  TabsAlignment as TabsALIGNMENT,
  TabsSkin as TabsSKIN,
} from 'wix-ui-tpa';
import { MemberChallenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';
import { ChallengeItem } from '../components/ChallengeItem';
import { LeaveChallengeModalProvider } from '../../../contexts/LeaveChallengeModal/LeaveChallengeModalProvider';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../settingsParams';

import { IWixStatic } from '@wix/native-components-infra/dist/src/types/wix-sdk';

// const ACTIVE_CHALLENGES_TAB = 0;
const ENDED_CHALLENGES_TAB = 1;

const isEndedChallenge = (challenge: MemberChallenge): boolean => {
  return [ParticipantState.COMPLETED, ParticipantState.FAILED].includes(
    challenge.summary?.participation?.state as any,
  );
};

export const MAChallengeList: React.FC<
  IChallengeListStateProps & IWixSDKContext
> = (props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [tab, setTab] = React.useState<number>(0);
  const { t } = useTranslation();
  const { isEditor, isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const { challengesListData } = props;

  const endedChallenges =
    challengesListData?.memberChallenges?.filter(isEndedChallenge) || [];
  const activeChallenges =
    challengesListData?.memberChallenges?.filter((challenge) => {
      return !isEndedChallenge(challenge);
    }) || [];

  const [items, setItems] = React.useState<MemberChallenge[]>(activeChallenges);

  const getTabsAlignment = React.useMemo(() => {
    if (isMobile) {
      return TabsALIGNMENT.center;
    }

    if (isRTL) {
      return TabsALIGNMENT.right;
    }

    return TabsALIGNMENT.left;
  }, [isMobile, isRTL]);

  React.useEffect(() => {
    let removeResizeListener: () => void;

    if (isEditor && props.Wix) {
      removeResizeListener = setWidthRestrictions(props.Wix as IWixStatic);
    }

    return () => {
      if (isEditor && props.Wix) {
        removeResizeListener();
      }
    };
  }, []);

  React.useEffect(() => {
    setItems(tab === ENDED_CHALLENGES_TAB ? endedChallenges : activeChallenges);
  }, [tab]);

  const removeChallenge = (challengeId: string) => {
    setItems(items.filter((item) => item.challenge.id !== challengeId));
  };

  const toggleIsLoading = () => {
    setIsLoading(!isLoading);
  };

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <Spinner />
      </div>
    );
  }

  return (
    <LeaveChallengeModalProvider showModal={null}>
      <div id="modal-root" />
      <div id="toast-root" />
      <div
        data-hook="my-challenge-list-wrapper"
        className={st(classes.root, { mobile: isMobile })}
        dir={isRTL ? 'rtl' : 'ltr'}
      >
        {!isMobile && <Title />}

        <Tabs
          className={classes.tabs}
          skin={TabsSKIN.fullUnderline}
          alignment={getTabsAlignment}
          items={[
            {
              title: t('members-area.tab.active'),
            },
            {
              title: t('members-area.tab.ended'),
            },
          ]}
          onTabClick={setTab}
          activeTabIndex={tab}
        />

        {!items?.length || props.showMAEmptyList ? (
          <EmptyList
            title={settings.get(settingsParams.emptyChallengesText) as string}
            onLinkClick={toggleIsLoading}
          />
        ) : (
          <ul data-hook="challenge-list" className={classes.wrapper}>
            {items.map((item, index) => {
              return (
                <li className={classes.wrapperItem} key={index}>
                  <ChallengeItem
                    item={item}
                    onChallengeRemove={removeChallenge}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </LeaveChallengeModalProvider>
  );
};

MAChallengeList.displayName = 'MAChallengeList';

export default MAChallengeList;
